@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.beyond-expectations {
  text-shadow: 8px 8px 8px #000000;
}


/*-----------SLIDER LOGOS PAGE CODE------------*/
/* .slider{
  margin-left: 5%;
  margin-right: 5%;
} */

/* .slidercontainer {
    padding-top: 30px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  
  }
  
  .slidercontainer .owl-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  
  .slidercontainer .owl-item img {
    opacity: 0.5;
    transition: 0.3s;
    filter: grayscale(100);
  }
  
  .slidercontainer .owl-item img:hover {
    filter: none;
    opacity: 1;
  } */

/*-----------END SLIDER LOGOS PAGE CODE------------*/
